import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'inspection-device-events',
        pathMatch: 'full'
      },
      {
        path: 'inspection-device-events',
        loadComponent: () =>
          import(
            './containers/inspection-device-events-container/inspection-device-events-container.component'
          ).then((m) => m.InspectionDeviceEventsContainerComponent),
        data: { label: 'inspectionDeviceView' },
        canActivate: [AuthGuard]
      },
      {
        path: 'tablet-events',
        loadComponent: () =>
          import(
            './containers/tablet-events-container/tablet-events-container.component'
          ).then((m) => m.TabletEventsContainerComponent),
        data: { label: 'tabletEventsView' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'status',
    loadComponent: () =>
      import('./containers/status-container/status-container.component').then(
        (m) => m.StatusContainerComponent
      )
  },
  {
    path: '**',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
